import React from "react";
import { useSharedData } from "../../../contexts/SharedData";
import { Slider } from "../../Common/Slider";
import { SLIDER_OPTIONS } from "../constants";
import { ProductImage } from "./ProductImage";
import { useXxlMediaQuery } from "../../../hooks/useXxlMediaQuery";

/**
FIXME:We should not be importing from next-app into react app.
This is a temporary solution to allow us to use the NextImage component in the react app,
since the stub NextImage component does not work.
XD-15933
*/
import { NextImage } from "../../../../../next-js-app/src/components/common/NextImage/NextImage";

const IMAGE_SIZE = {
  mobile: 138,
  desktop: 210,
} as const;

const MobileImageCarousel = ({
  listOfProductUrls,
  isImgPrioPossible,
  imageAltText,
  columnAmount,
  isReactApp,
}: {
  listOfProductUrls: string[];
  isImgPrioPossible: boolean;
  imageAltText?: string;
  columnAmount: number;
  isReactApp: boolean;
}) => {
  const products = listOfProductUrls.map((image, index) => {
    const isPrio = index === 0 && isImgPrioPossible;

    if (isReactApp) {
      return (
        <ProductImage
          key={index}
          alt={imageAltText}
          columnAmount={columnAmount}
          size={IMAGE_SIZE.mobile}
          fetchPriority={isPrio ? "high" : "low"}
          imageUrl={image}
          loading={isPrio ? "eager" : "lazy"}
        />
      );
    }

    return (
      <NextImage
        key={index}
        src={image}
        alt={imageAltText ?? "product image"}
        height={IMAGE_SIZE.mobile}
        width={IMAGE_SIZE.mobile}
        priority={isPrio}
      />
    );
  });
  return (
    <Slider
      items={products}
      slidesConfig={{
        perView: SLIDER_OPTIONS.slidesPerView,
        spacing: SLIDER_OPTIONS.spacing,
      }}
      mode={SLIDER_OPTIONS.mode}
      dragSpeed={SLIDER_OPTIONS.dragSpeed}
      loadInitiallyOnlyFirstSlide={true}
      navigation={"ARROWS_SMALL"}
    />
  );
};

type Props = {
  columnAmount: number;
  imageAltText?: string;
  isFocused?: boolean;
  listOfProductUrls: string[];
  positionInList?: number;
  primaryImageUrl?: string;
  prioritizeImageLoad?: boolean;
  showOnlyOneImage?: boolean;
};

const MobileImageCarouselOrSingleProductImage = ({
  columnAmount,
  imageAltText,
  isFocused = false,
  listOfProductUrls,
  positionInList = -1,
  primaryImageUrl,
  prioritizeImageLoad = false,
  showOnlyOneImage = false,
}: Props) => {
  const { isReactApp } = useSharedData();
  const isMobileOrTablet = useXxlMediaQuery("MobileAndTabletMediaQuery");

  const isImgPrioPossible =
    prioritizeImageLoad && isMobileOrTablet
      ? positionInList < 2
      : positionInList < 6;
  const shouldRenderSlider =
    isMobileOrTablet && listOfProductUrls.length > 1 && !showOnlyOneImage;

  if (shouldRenderSlider) {
    return (
      <MobileImageCarousel
        listOfProductUrls={listOfProductUrls}
        isImgPrioPossible={isImgPrioPossible}
        columnAmount={columnAmount}
        isReactApp={isReactApp}
      />
    );
  }

  const [, secondImage] = listOfProductUrls;
  return (
    <ProductImage
      alt={imageAltText}
      columnAmount={columnAmount}
      fetchPriority={isImgPrioPossible ? "high" : "low"}
      focusImageUrl={secondImage}
      imageUrl={primaryImageUrl}
      isFocused={isFocused}
      loading={isImgPrioPossible ? "eager" : "lazy"}
      size={isMobileOrTablet ? IMAGE_SIZE.mobile : IMAGE_SIZE.desktop}
    />
  );
};

export { MobileImageCarouselOrSingleProductImage };
